
function Mensaje(){

    return(
        <div>
        <div className='card mt-3'>
        <div className="card-body">
              <div className='col-1 '>
                  <span className="material-symbols-outlined">
                    warning
                  </span>
              </div>

              <div className='col-11'>
                 <p className='text-danger'> IMPORTANTE! Para continuar, debe seleccionar en el menú que tipo de computadora desea desbloquear.</p>
             </div>

        
        </div>
        </div> 
        

       
      </div>
    )
}

export default Mensaje;