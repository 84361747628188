import React, { useEffect, useState, useHistory } from 'react';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { BrowserAuthError } from "@azure/msal-browser"; 

import { useIsAuthenticated } from "@azure/msal-react";

import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";

import imagenProvincia from '../assets/img/provincia2.jpg';
import IRM_2 from '../assets/software/IRM_2.6.bat';
import whatsapp from '../assets/img/whatsapp.svg'
import ErrServidor from '../assets/img/ErrServidor.png'

import EnviarMail from './enviarMail';

const videoArrProvincia = "https://www.youtube.com/embed/3vgZwCx4XXU?si=DEii-X6CPYh8XLga" ;


const Provincia = () => {

 /* useEffect(() => {
    const contentElement = document.querySelector('#bottomOf');
    if (contentElement) {
      contentElement.scrollIntoView({ behavior: 'smooth' });
    }

  })*/


  const { accounts, inProgress } = useMsal();

  let usuario;

 // console.log(instance)

  const isAuthenticated = useIsAuthenticated();
  const [data, setData] = useState({});
  const [isValid, setIsValid] = useState(false);

  const [msn, setMSN] = useState('');
  const [idEquipo, setID] = useState('');

  const [ct, setCT] = useState('');
  const [uc, setUC] = useState('');

  const [solucion, setSolucion] = useState({});



  if(accounts.length != 0){

    usuario = {
        nombre: accounts[0].name,
        correo: accounts[0].username
    }

   }

  let contMSN = document.getElementById('contMSN')
  let contSelEquipo = document.getElementById('contSelEquipo')
  let contCT = document.getElementById('contCT')
  let contSolucion = document.getElementById('contSolucion')
  let input_msn = document.getElementById('input_msn')

  const selMSN = () =>{
      

    contSelEquipo && contSelEquipo.classList.add('d-none')
    contCT && contCT.classList.add('d-none')
    contSolucion && contSolucion.classList.add('d-none')

     setMSN('') 
     setData('')  
     setID('')
     setCT('')
     setUC('')
     setSolucion('')

  };

  const { instance } = useMsal();
  
  const handleLogin = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      // Usar redirección para móviles
      instance.loginRedirect()
        .then(response => {
          console.log("Login success:", response);
        })
        .catch(error => {
          console.error("Login failed:", error);
        });
   
  }; // Make sure this closing brace exists!
  

  const cambioMSN = (e) =>{
     
     contSelEquipo && contSelEquipo.classList.add('d-none')
     contCT && contCT.classList.add('d-none')
     contSolucion && contSolucion.classList.add('d-none')

     setData('')  
     setMSN(e.target.value) 
     setIsValid(e.target.value.length >= 8);
  }

  const [sesion, setSesion] = useState({
    id:'',
    correo:'',
    usuario: '',
    msn: '',
    bios_number: '',
    ct_uc: '',
    solucion: '',
    descarga: '',
    wsp: ''
});

const [idSesion, setIdSesion] = useState();

const iniciarSesion = async () => {

  const options = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        msn: msn,
        usuario: usuario.nombre,
        correo: usuario.correo
      })
  };

  try {
      const response = await fetch('https://pjb.edu.ar/api/ctrdesbloqueos/pixart', options);
      
      if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const result = await response.json();
      setIdSesion(result.data.id)

      return true;

  } catch (err) {
      console.error('Error al iniciar sesión:', err);
      return false; // Indica que hubo un problema
  }
};

const modificarSesion = async (nuevosDatos) => {

  const updatedSesion = {
      ...sesion,
      ...nuevosDatos
  };

  const options = {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedSesion)
  };

  console.log(updatedSesion);

  try {
      const response = await fetch('https://pjb.edu.ar/api/ctrdesbloqueos/pixart?id=' + idSesion, options);
      const result = await response.json();
      
      console.log(result);
      setSesion(updatedSesion);

      console.info('update ctr OK');
      return true;

  } catch (err) {
      console.error(err);
  }
};

const buscarEquipo = async () => {

  const inicioExitoso = await iniciarSesion();

  if (inicioExitoso) {
    console.info('ctr OK');
  } else {
    console.error('No se pudo iniciar sesión. Modificar sesión no se ejecutará.');
  }


  
      let contWsp = document.getElementById('contWsp');
      contWsp.classList.remove('d-none');

      contSelEquipo && contSelEquipo.classList.remove('d-none');
      contCT && contCT.classList.add('d-none');
      contSolucion && contSolucion.classList.add('d-none');

      setData('');
      setID('');
      setCT('');
      setUC('');
      setSolucion('');

      try {
          const response = await fetch('https://api-desbloqueos.educacioncba.edu.ar/api/v1/pixart/devices?msn=' + msn);
          const data = await response.json();

          setData(data);

          if (data.resultado === false) {
              contSelEquipo.classList.add('d-none');
              input_msn.classList.add('is-invalid');
          } else {
              input_msn.classList.remove('is-invalid');
              input_msn.classList.add('is-valid');
             
              contSelEquipo.scrollIntoView({ behavior: 'smooth' });


             if(data.data.length){
              data.data.forEach(equipo => {
                contSelEquipo && contSelEquipo.classList.add('d-none');
                selEquipo(equipo.id)
              });
             }

          }


      } catch (error) {
          console.error('Error fetching data for Equipos provincia:', error);
      }
 
};
  const selEquipo = (id_equipo) => {
     
     contCT.classList.remove('d-none')
     contCT.scrollIntoView({ behavior: 'smooth' });
     //console.log('equipo: '+ id_equipo)
     setID(id_equipo)
     
  } 

  const cambioCT = (e) =>{
    setCT(e.target.value) 
 }

 const cambioUC= (e) =>{
  setUC(e.target.value) 
}

const generarCodigo = async () =>{

  modificarSesion(
    {
      id: idSesion,
      usuario:usuario.nombre,
      correo: usuario.correo,
      msn: msn,
      ct_uc : ct +"-"+uc,
    }   
  )

  setSolucion('')
  cargarSolucion()

  const options = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: '{"ct":"'+ct+'","uc":"'+uc+'"}'

    };

await fetch('https://api-desbloqueos.educacioncba.edu.ar/api/v1/pixart/devices/'+ idEquipo +'/unlock', options)
    .then(response => response.json())
    .then(data => { 
      setSolucion(data);  
      console.log(data)

      modificarSesion(
        {
          id: idSesion,
          usuario:usuario.nombre,
          msn: msn,
          ct_uc : ct +"-"+uc,
          solucion: data.data
        }   
      )

     })
      
    .catch(err => console.error(err));

}

const cargarSolucion = () => {

  contSolucion.classList.remove('d-none')
  contSolucion.scrollIntoView({ behavior: 'smooth' });

}

const controlDescarga = () =>{

  modificarSesion(
    {
      id: idSesion,
      usuario:usuario.nombre,
      correo: usuario.correo,
      msn: msn,
      ct_uc : ct +"-"+uc,
      descarga : 'OK'
    }   
  )
}

const controlWsp = () =>{

  modificarSesion(
    {
      id: idSesion,
      usuario:usuario.nombre,
      correo: usuario.correo,
      msn: msn,
      ct_uc : ct +"-"+uc,
      wsp : 'OK'
    }   
  )
}


useEffect(() => {
  document.addEventListener('keydown', detectkeyDown, true)
}, [])

const detectkeyDown = (e) =>{
  
  
    if(e.key == 'Enter'){

      console.log(msn)
      console.log(idEquipo)
      console.log(data)


    }else{
     // console.log('hiciste click en: ', e.key)
    }


}

  return (
      <div>
        
        <AuthenticatedTemplate>
           
            <div className="container contOption">

                  <div className="card mb-3">
                    <div className="card-header">
                      Usuario
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">Hola, {isAuthenticated && usuario.nombre}</h5>
                      <p className="card-text">Sistema de desbloqueo de equipos entregados por la provincia de Córdoba</p>
                      { isAuthenticated ? <SignOutButton /> : <SignInButton /> }
                    </div>
                  </div>
           
                  <div className="card">
                    <div className="card-body">
                    <h2 className="card-title">Provincia</h2> 
                      <div className='row mt-3'>
                        <div className='col-1 mt-2'>
                            <span className="material-symbols-outlined">
                              error
                            </span>
                          
                        </div>
                      <div className='col-10'>
                          <p className='text-warning'> IMPORTANTE! Para realizar el desbloqueo debés prender el equipo como se muestra en la imagen, 
                            ya que utilizaremos los numeros que se observan en la pantalla</p>
                      </div>
                      </div>
                    
                
                    <div className="row"> 
                    
                    <div className="col-12 d-flex justify-content-center">
                        <img 
                          src={imagenProvincia}
                          className="rounded mx-auto d-block img-fluid" 
                          width="560" 
                          height="315" 
                          alt="fotografia real de netbook bloqueada"
                        />
                      </div>


                      </div>

                    </div>

                </div>

                <div className="card mb-4 mt-4" id="contMSN">
                    <div className="card-body">

                            <div className='row mt-2 mb-2'>
                                <div className='col-1'>
                                    <span className="material-symbols-outlined">
                                      error
                                    </span>
                                </div>
                                <div className='col-10'>
                                  <p className='text-dark'>
                                      Debés ingresar el numero "Machine Serial Number (msn)" de tu netbook. 
                                  </p>
                                </div>
                          </div>

                          <div className='row'>                
                              <div className="col-12 col-md-10 mt-3">
                                <div className="input-group">
                                    <span className="input-group-text" id="inputGroup-sizing-default">msn</span>
                                    <input 
                                        type="text" 
                                        id="input_msn" 
                                        value={msn}
                                        onChange={cambioMSN} 
                                        minLength={8}
                                        className="form-control" 
                                        aria-label="Sizing example input" 
                                        aria-describedby="inputGroup-sizing-default"

                                      />
                                    <div className="invalid-feedback" id="mensajeError">
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-10 d-flex justify-content-end'>
                                <p className={msn.length < 8 ? 'text-warning mx-2' : 'text-success mx-2'} >Caract.Min (8): {msn.length}</p>
                            </div>
                            <div className='col-12 d-flex justify-content-end'>
                                <button type="submit" 
                                    className="btn btn-outline-secondary mt-3 mx-2" 
                                    onClick={buscarEquipo}
                                    disabled={!isValid}
                                    >
                                    Continuar
                                </button>
                            </div>
                          </div>
                        </div>

              </div>


            <div className="card mb-4 mt-4 d-none" id="contSelEquipo">
                    <div className="card-body">

                            <div className='row mt-2 mb-2'>
                                <div className='col-1'>
                                    <span className="material-symbols-outlined">
                                      error
                                    </span>
                                </div>
                                <div className='col-10'>
                                  <p className='text-dark'>
                                      Para continuar debés seleccionar el recuadro que corresponde a tu equipo. 
                                  </p>
                                </div>
                          </div>

                          <div className='row'>
                          {data.resultado == true ? (
                                <div>
                                  <div className="list-group">
                                  <p>{data.resultado}</p>
                                    {data.data.map(item => (
                                      <a className="list-group-item list-group-item-action" key={item.id} onClick={() => selEquipo(item.id)}>
                                          
                                          <p><strong>{item.msn}</strong></p>
                                          <p>BIOS Number: {item.bios_number}</p>
                                      
                                      </a> 
                                    ))}
                                  </div>
                                </div>
                              ):(
                                <div className='col-12 text-center'>

                                  <div className="spinner-border" role="status">
                                      <span className="visually-hidden">Loading...</span>
                                  </div>
                                  <br/>
                                  <p>Buscando</p>
                                </div>  
                              )}
                          </div>
                    </div>
            </div>
                            
{/*
            <div className="card mb-4 mt-4 d-none" id="contSelEquipo">
              <div class="text-center">
                  <img src={ErrServidor} class="rounded mt-2" alt="..." width="100" height="100"></img>
                  <h4>Disculpe las molestias, estamos actualizando el modulo para brindarle un mejor servicio.</h4> 
                                  
                  <a                                                            
                    href={"https://wa.me/+5493516667355?text=Incoveniente con el equipo --> " + "MSN:" + msn + " ct:"+ct+ " uc:"+uc} target="_blank" rel="noopener noreferrer"
                    type="submit" 
                    className="btn btn-outline-success mb-2 mt-3" 
                  >

                  <img 
                            className="p-1" 
                            src={whatsapp} 
                            width="40" height="40"
                            alt="Whatsapp"                                 
                      />  
                    Comunicate 
                
                </a>

                  <h6 className='mt-2'>Comunicate con nosotros por medio de whatsapp para recibir asistencia en el desbloqueo de tu equipo</h6>    
              </div>            
            </div>
                          */}
            <div className="card mb-4 mt-4 d-none" id="contCT">
                    <div className="card-body">

                            <div className='row mt-2 mb-2'>
                                <div className='col-1'>
                                    <span className="material-symbols-outlined">
                                      error
                                    </span>
                                </div>
                                <div className='col-10'>
                                  <p className='text-dark'>
                                      Ingresá los numeros ct y uc  que aparecen en la pantalla de su computadora para generar código de desbloqueo.
                                  </p>
                                </div>
                            </div>

                          <div className='row'>
                                <div className="input-group mt-3">
                                      <span className="input-group-text" id="inputGroup-sizing-default">CT</span>
                                      <input 
                                          type="text" 
                                          value={ct}
                                          onChange={cambioCT}
                                          className="form-control"  
                                          required
                                      />
                                </div> 
                                <div className="input-group mb-2 mt-3">
                                      <span className="input-group-text" id="inputGroup-sizing-default">UC</span>
                                      <input 
                                          type="text" 
                                          value={uc}
                                          onChange={cambioUC}
                                          className="form-control"  
                                          required
                                      />
                                </div> 
                          </div>
                          <div className='row'>
                              <div className='col-12 d-flex justify-content-end'>
                                    <button type="submit" 
                                        className="btn btn-outline-secondary mt-3 mx-2" 
                                        onClick={generarCodigo}>
                                        Continuar
                                    </button>
                                </div>
                          </div>
                    </div>              
            </div>

            <div className="card mb-4 mt-4 d-none" id="contSolucion">
                    <div className="card-body">

                            <div className='row mt-2 mb-2'>

                                <div className='col-10'>
                                  <h4 className='text-dark'>
                                    Solución
                                  </h4>
                                </div>
                            </div>


                                {solucion.resultado == true ? (
                                    <div className='row'>
                                        <div className='col-12'>
                                          <div className='row'>
                                            <div className='col-1'>
                                                <span className="material-symbols-outlined">counter_1</span>
                                            </div>
                                            <div className='col-10'><p>Debés ingresar el siguiente código en la netbook a desbloquear</p></div>
                                          </div>
                                          
                                        </div>
                                          <div className='col-12 col-md-9 text-center'>
                                            <h1 className='text-success'>{solucion.data}</h1>
                                        
                                          </div>
                                        <div className='col-12 mt-3'>
                                            <div className='row'>
                                              <div className='col-1'>
                                                <span className="material-symbols-outlined">counter_2</span>
                                              </div>
                                              <div className='col-10'>
                                                <p>Descargá el archivo y seguí las intrucciones del video</p>
                                                </div>
                                              
                                            </div>
                                            <div className='row'>
                                              <div className='col-12 col-md-9  d-flex justify-content-center'>
                                                  <a 
                                                    className="btn btn-outline-success mt-3 mb-3" 
                                                    href={IRM_2}
                                                    onClick={controlDescarga} 
                                                    download="IRM_2.6.bat">
                                                      Descarga
                                                  </a>
                                              </div>
                                            </div>
                                        </div>   

                                        <div className='col-12 col-md-9 mb-3 mt-3 d-flex justify-content-center'>
                                              <iframe 
                                                    width="360" 
                                                    height="215" 
                                                    src= {videoArrProvincia}
                                                    title="Video explicativo de como cargar arranques en 2 minutos en la netbook de provinica"                                       
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                                    >
                                                </iframe>
                                        </div>  
                                    
                                        <div className='col-12 d-flex justify-content-end'>
                                              <button type="submit" 
                                                  className="btn btn-outline-secondary mt-3 mx-2" 
                                                  onClick={selMSN}>
                                                  Nueva Busqueda
                                              </button>
                                          </div>
                                

                                    </div>

                                  
                                    ):(
                                      <div className='col-12 text-center'>

                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                        <br/>
                                        <p>Buscando</p>
                                      </div>  
                                    )}

                                
                                
                            </div>
                    </div>


                    <div className='col-12 mb-3 mt-2 d-none' id="contWsp">
                                    <a                                                                           
                                        href={"https://wa.me/+5493516667355?text=Tengo un incoveniente con el equipo --> " + "MSN:" + msn + " ct:"+ct+ " uc:"+uc} target="_blank" rel="noopener noreferrer"
                                        type="submit"
                                        onClick={controlWsp} 
                                        className="btn btn-outline-success whatsapp" 
                                    >
                                      <img 
                                                className="p-1" 
                                                src={whatsapp} 
                                                width="40" height="40"
                                                alt="Whatsapp"                                 
                                          />  
                                        Comunicate 
                                    
                                    </a>
                              </div>
            </div>   
            <div id="bottomOf"></div>   
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <div className="container contOption">
                      <div className="row ">
                          <div className="col-12" >
                              
                        
                                  <h3 className='text-secondary mb-4'>Inicio de sesión</h3>          
                                  <h5 className='text-secondary mb-3'><em>Ingresar con la cuenta institucional provista por el Ministerio de Educación de la Provincia de Córdoba</em></h5> 
                                  <h6 className='mb-5'><em>@me.cba.gov.ar</em></h6>

                                  { /*isAuthenticated ? <SignOutButton /> : <SignInButton />*/ }
                                  <button class="ml-auto btn btn-outline-secondary " onClick={handleLogin}>Iniciar sesión</button>


                          </div>

                      </div>
            </div>
        </UnauthenticatedTemplate>

  
      </div>  
        
  );
};

export default Provincia;
